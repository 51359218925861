<template>
  <!--begin::List Widget 1-->
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Header-->
      <div class="px-9 pt-9 card-rounded">
        <!--begin::Heading-->
        <div class="d-flex flex-stack mb-6">
          <div class="d-flex flex-row align-items-center">
            <h3 class="m-0 fw-bolder fs-3 me-3">Pending prospects</h3>
          </div>
          <!-- <router-link :to="`/patients`" class="btn btn-light btn-sm"
            >See all patients</router-link
          > -->
        </div>
        <!--end::Heading-->
      </div>
      <!--end::Header-->

      <!--begin::Email list-->
      <!-- <div v-if="messagesLoading" class="pt-12 pb-8 text-center">
        <p>
          <span class="spinner-border spinner-border-sm align-middle me-2" />
          Loading...
        </p>
      </div>
      <div v-else class="px-9 pb-2"> -->
      <div class="px-9 pb-2">
        <div v-if="prospects.length">
          <div class="row pt-2">
            <div class="col-3">
              <p class="text-muted">Name</p>
            </div>
            <div class="col-2">
              <p class="text-muted">Request</p>
            </div>
            <div class="col-3">
              <p class="text-muted">Date added</p>
            </div>
            <div class="col-2">
              <p class="text-muted">Inquiry no.</p>
            </div>
          </div>
          <div
            v-for="(prospect, idx) in prospects"
            :key="idx"
            class="py-6 row patient-row"
          >
            <p class="col-3 mb-0">
              {{ prospect.lastname }} {{ prospect.firstname }}
            </p>
            <p class="col-2 mb-0">{{ prospect.request }}</p>
            <p class="col-3 mb-0">
              {{
                new Date(prospect.createdAt).toLocaleDateString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              }}
              <i>accept until {{
                (new Date(prospect.createdAt)).addDays(9).toLocaleDateString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              }}</i>
            </p>
            <p class="col-2 mb-0">
              <span v-if="prospect.prospectAcceptanceNumberInMonth">
                {{ withOrdinalSuffix(prospect.prospectAcceptanceNumberInMonth) }}
                inquiry of the month
              </span>
            </p>
            <div class="btn-group col-2 align-middle" role="group">
                <button v-if="prospectsLoading.includes(prospect.id)" class="btn btn-outline-primary" disabled>
                  <span class="spinner-border spinner-border-sm align-middle" />
                </button>
                <template v-else>
                  <button
                    class="btn btn-sm btn-success"
                    title="Accept this prospect"
                    @click.prevent="acceptProspect(prospect)"
                  >
                    Accept
                  </button>
                </template>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-center py-12 text-muted">You have no hot prospect pending approval.</p>
        </div>
      </div>
      <!--end::Email list-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::List Widget 1-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

Date.prototype.addDays = function(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default {
  components: {},
  emits: ['hot-prospect-accepted'],
  data() {
    return {
      isLoading: false,
      prospects: [],
      prospectsLoading: [],
    };
  },
  mounted() {
    this.fetchItemsFromApi();
  },
  methods: {
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isLoading = true;

      const apiParams = {
        status: "HOT_PROSPECT_PENDING_APPROVAL",
        limit: 5,
        sortBy: "createdAt:asc",
      };

      ApiService.query("/patients", { params: apiParams })
        .then(({ data }) => {
          this.prospects = data.results;
          console.log(this.prospects);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    acceptProspect(prospect) {
        Swal.fire({
            title: 'Confirm prospect acceptance',
            html: `After accepting <strong>${prospect.firstname} ${prospect.lastname}</strong>, you will be automatically charged on your default payment method.`,
            icon: "info",
            confirmButtonText: "Ok, go ahead!",
            showCancelButton: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
                cancelButton: "btn fw-bold btn-light-secondary",
            },
        }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            this.prospectsLoading.push(prospect.id);
            ApiService.post(
              `/patients/${prospect.id}/process`,
                { action: 'accept' },
            )
              .then((res) => {
                this.fetchItemsFromApi();
                this.prospectsLoading = this.prospectsLoading.filter(e => e !== prospect.id);
                this.$emit('hot-prospect-accepted'); // Triggers refresh patients widget
               
                console.log("RES RES RES", res);
                // PAID or SEPA
                if (res.data.status == "paid" || res.data.method == "directdebit") {
                  Swal.fire({
                    title: "Prospect accepted",
                    text: "They will appear on your dashboard now.",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                } else {
                  Swal.fire({
                    title: "Prospect accepted",
                    text: "They will appear on your dashboard when your payment has been received. We'll keep you updated by email.",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                }
              })
              .catch(e => {
                this.prospectsLoading = this.prospectsLoading.filter(e => e !== prospect.id);
              });
        });
    },
    rejectProspect(prospect) {
        Swal.fire({
            title: 'Confirm prospect rejection',
            html: `After rejecting <strong>${prospect.firstname} ${prospect.lastname}</strong>, you will not be able to accept them in the future.`,
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Confirm rejection",
            showCancelButton: true,
            customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
                cancelButton: "btn fw-bold btn-light-secondary",
            },
        }).then(({ isConfirmed }) => {
            if (!isConfirmed) return;
            this.prospectsLoading.push(prospect.id);
            ApiService.post(
              `/patients/${prospect.id}/process`,
                { action: 'reject' },
                { responseToast: { text: `Patient successfully rejected` } }
            )
              .then(() => {
                this.fetchItemsFromApi();
                this.prospectsLoading = this.prospectsLoading.filter(e => e !== prospect.id);
              })
              .catch(e => {
                this.prospectsLoading = this.prospectsLoading.filter(e => e !== prospect.id);
              });
        });
    },
    withOrdinalSuffix(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) return i + "st";
      if (j == 2 && k != 12) return i + "nd";
      if (j == 3 && k != 13) return i + "rd";
      return i + "th";
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-row {
  border-bottom: 0.6px solid #e4e6ef;

  &:last-of-type {
    border-bottom: none;
  }
}
</style>
